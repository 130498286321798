import { SelectableValue, DataQuery, DataSourceJsonData } from '@grafana/data'
import { K6DataSource } from 'datasource/datasource'

export interface K6AppSettings {
  customText?: string
  customCheckbox?: boolean
}

export interface Settings {
  ds: K6DataSource
  query?: string
  index?: number
  components?: any[]
}

export interface TestsPageSettings extends Settings {}

export interface TestsPageState {
  org: string
  orgs: SelectableValue[]
  project: string
  projects: SelectableValue[]
  tests: Test[]
}

export type Tags = any[]

export interface Metric2 {
  check_id?: any
  contains: string
  group_id?: any
  id: string
  name: string
  tags: Tags
  test_run_id: number
  type: MetricType
}

export interface Agg {}

export interface Value {
  timestamp: string
  value: any
}

export interface Serie {
  agg: Agg
  id: string
  method: string
  metric_id: string
  test_run_id: number
  values: Value[]
}

export interface Meta {
  count: number
}

export interface Metrics {
  'k6-metrics': Metric2[]
  'k6-serie': Serie[]
  meta: Meta
}

export interface GrpcMetricSummary {
  duration: Stats
  requests_count: number
  rps_max: number
  rps_mean: number
}

export interface HttpMetricSummary {
  duration: Stats
  failures_count: number
  requests_count: number
  rps_max: number
  rps_mean: number
}

export interface Stats {
  count: number | null
  max?: number | null
  mean?: number | null
  min?: number | null
  p95?: number | null
  p99?: number | null
  stdev?: number | null
}

export interface WsMetricSummary {
  connecting: Stats
  msgs_received: number
  msgs_sent: number
  ping: Stats
  session_duration: Stats
  sessions: number
}

export interface Summary {
  grpc_metric_summary: GrpcMetricSummary
  http_metric_summary: HttpMetricSummary
  ws_metric_summary: WsMetricSummary
}

export interface HttpValue {
  group_id?: any
  http_metric_summary: HttpMetricSummary
  id: string
  method: string
  name: string
  scenario_id: string
  status: number
  test_run_id: number
}

export interface Http {
  '@count': number
  value: HttpValue[]
}

export interface VariableQuery {
  qtype: VariableQueryType
  query: string
}

export enum VariableQueryType {
  ORGANIZATIONS,
  PROJECTS,
  TESTS,
  TEST_RUNS,
}

export enum QueryType {
  METRIC = 'metric',
  URLS = 'urls',
  CHECKS = 'checks',
  THRESHOLDS = 'thresholds',
}

/**
 * These are options configured for each DataSource instance
 */
export interface CloudDataSourceOptions extends DataSourceJsonData {
  k6ApiEnv?: string
}

/**
 * Value that is used in the backend, but never sent over HTTP to the frontend
 */
export interface CloudSecureJsonData {
  apiToken?: string
}

export interface TestRunListItem {
  /*organizationId: number;
  organizationName: string;
  projectId: number;
  projectName: string;
  testId: number;
  testName: string;
  testCreated: Date;*/
  testRunId: number
  testRunCreated: Date
  testRunStarted: Date
  testRunStartedEpoch: number
  testRunEnded: Date
  testRunEndedEpoch: number
  testRunStatus: number
  testRunResultStatus: number
  testRunVUs: number
  testRunDuration: number
  testRunLoadTime: number
}

export interface Organization {
  id: number
  name: string
}

export interface Project {
  id: number
  name: string
  organization_id: number
  organization_name: string
  is_default: boolean
  is_k6: boolean
}

export interface Test {
  id: number
  created: string
  name: string
  // projectID: string;
  test_runs: any[]
  project_id: number
  creation_process: string
  last_test_run_id: number
}

export interface Tests {
  'k6-tests': Test[]
  meta: {
    count: number
  }
}

export const BadgeColors = {
  RED: 'red',
  BLUE: 'blue',
  GREEN: 'green',
  ORANGE: 'orange',
  PURPLE: 'purple',
  GRAY: 'gray',
}

export const BadgeColorCodes = {
  [BadgeColors.RED]: '#dc3545',
  [BadgeColors.GREEN]: '#28a745',
  [BadgeColors.ORANGE]: '#f1c40f',
  [BadgeColors.GRAY]: '#b5b5b5',
  [BadgeColors.BLUE]: '#168ce8',
  [BadgeColors.PURPLE]: '#9b59b6',
}

export enum BadgeIcons {
  SPINNER = 'fa fa-spinner',
  CHECK = 'check',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  MINUS_CIRCLE = 'minus-circle',
}

export const TestRunStatus = {
  CREATED: -2,
  VALIDATED: -1,
  QUEUED: 0,
  INITIALIZING: 1,
  RUNNING: 2,
  FINISHED: 3,
  TIMED_OUT: 4,
  ABORTED_USER: 5,
  ABORTED_SYSTEM: 6,
  ABORTED_SCRIPT_ERROR: 7,
  ABORTED_THRESHOLD: 8,
  ABORTED_LIMIT: 9,
}

export const TestRunStatusText = {
  [TestRunStatus.CREATED]: 'Created',
  [TestRunStatus.VALIDATED]: 'Validated',
  [TestRunStatus.QUEUED]: 'Queued',
  [TestRunStatus.INITIALIZING]: 'Initializing',
  [TestRunStatus.RUNNING]: 'Running',
  [TestRunStatus.FINISHED]: 'Finished',
  [TestRunStatus.TIMED_OUT]: 'Timed out',
  [TestRunStatus.ABORTED_USER]: 'Aborted by user',
  [TestRunStatus.ABORTED_SYSTEM]: 'Aborted by system',
  [TestRunStatus.ABORTED_SCRIPT_ERROR]: 'Aborted script error',
  [TestRunStatus.ABORTED_THRESHOLD]: 'Aborted by threshold',
  [TestRunStatus.ABORTED_LIMIT]: 'Aborted by limit',
}

export const TestRunResultStatus = {
  PASSED: 0,
  FAILED: 1,
}

export const TestRunResultStatusText = {
  [TestRunResultStatus.PASSED]: 'Test passed',
  [TestRunResultStatus.FAILED]: 'Test failed',
}

export enum TestRunProcessingStatus {
  UNRUN = 0,
  PROCESSING = 1,
  FINISHED = 2,
  ERROR = 3,
}

export const RUN_PROCESS = {
  K6_INGEST: 'k6 to Ingest',
  K6_CLOUD: 'k6 to Cloud',
}

export const CREATION_PROCESS = {
  REQUEST_BUILDER: 'UI-REQUEST-BUILDER',
  SCRIPT_HANDWRITTEN: 'UI-SCRIPT-HANDWRITTEN',
  SCRIPT_CHROME: 'UI-SCRIPT-BROWSER-CHROME',
  SCRIPT_FIREFOX: 'UI-SCRIPT-BROWSER-FIREFOX',
  K6_CLOUD: 'K6-CLOUD',
}

export const CREATION_PROCESS_TEXTS = {
  [CREATION_PROCESS.REQUEST_BUILDER]: 'Test builder test',
  [CREATION_PROCESS.SCRIPT_HANDWRITTEN]: 'Script test',
  [CREATION_PROCESS.SCRIPT_CHROME]: 'Recorded test',
  [CREATION_PROCESS.SCRIPT_FIREFOX]: 'Recorded test',
  [CREATION_PROCESS.K6_CLOUD]: 'CLI k6 test',
}

export const K6TestRunStatusesActive = [
  TestRunStatus.RUNNING,
  TestRunStatus.CREATED,
  TestRunStatus.VALIDATED,
  TestRunStatus.QUEUED,
  TestRunStatus.INITIALIZING,
]

export enum BackgroundTaskType {
  TEST_RUN = 5,
  TEST_RESULT_EXPORT = 6,
}

// testRun delete_status
export const DELETE_STATUS = {
  DELETABLE: 0,
  // will never be hit by data-retention. This is set when you select a test-run to be 'safe'
  NOEXPIRE: 1,
  // also safe, but this is set by us to avoid a user deleting or changing a test (for debug purposes)
  READONLY: 2,

  // These higher numbers track why a test-run was deleted:
  DELETED_USER: 3, //- test-run was already deleted by the user
  DELETED_EXPIRE: 4, //- test-run deleted by expired timeout (data retention)
  DELETION_IN_PROGRESS: 5, //- temporary state when waiting for metrics to be wiped.
}

export interface BackgroundTask {
  type: number
  test_run_id: number
}

export interface TestRunNode {
  load_zone_id: string
  public_ip: string
  size: string
}

export enum MetricType {
  COUNTER = 'counter',
  GAUGE = 'gauge',
  RATE = 'rate',
  TREND = 'trend',
}

export type MetricTypeName = 'counter' | 'gauge' | 'rate' | 'trend'

export interface Url {
  id: string
  projectId: number
  testRunId: number
  groupId?: string
  metrics?: Metric[]
  url: string
  method: string
  status: number
  httpStatus: number
  isWebSocket: boolean
  count: number
  loadTime: number
  min: number
  max: number
  mean: number
  stdev: number
  p95: number
  p99: number
}

export interface Check {
  id: string
  projectId: number
  testRunId: number
  groupId?: string
  metrics?: Metric[]
  name: string
  firstSeen: Date
  success: boolean
  successCount: number
  totalCount: number
}

export interface Threshold {
  id: string
  testRunId: number
  metricId: string
  name: string
  stat: string
  calculatedValue: number
  tainted: boolean
  taintedAt?: Date
  value: number
}

export interface Metric {
  id: string
  name: string
  type: MetricType
  contains: string
  groupId?: string
  urlId?: string
  thresholdId?: string
  checkId?: string
  tags?: Map<string, string>
}

export interface SeriesDataPoint {
  timestamp: string
  value: number
}

export interface Series {
  id: string
  metricId: string
  aggregation: string
  values: SeriesDataPoint[]
}

export interface SeriesTag {
  id: number
  key: string
  value: string
}

export interface LogsFilter {
  level?: string
  text?: string
  loadZone?: string
}

export interface Query extends DataQuery {
  queryType: QueryType
  qtype: QueryType
  entity?: string
  projectId?: number | string
  testId?: number | string
  testRunId?: number | string
  metric?: string
  aggregation?: string
  tags?: SeriesTag[]
  uid?: string
  logsFilter?: LogsFilter
}

export interface Org {
  id: number
  name: string
  is_default: boolean
}

export interface Overview {
  checks_hits_successes: number
  checks_hits_total: number
  checks_successes: number
  checks_total: number
  http_req_duration_avg: number
  http_reqs_avg: number
  test_run_id: number
  thresholds_successes: number
  thresholds_total: number
  urls_hits_successes: number
  urls_hits_total: number
  urls_successes: number
  urls_total: number
}

export interface FormattedRuns {
  loadTimes: number[]
  dateStrings: string[]
  status: any
}

export interface LoadZone {
  id: number
  name: string
  vendor: string
  country: string
  city: string
  aggregate_region: boolean
  latitude: number
  longitude: number
  configurable: boolean
  is_k6_only: boolean
  k6_load_zone_id?: string | null
  public: boolean
}

type User = {
  application_type: unknown
  company: string
  company_size: number
  country: string
  date_joined: string
  email: string
  first_name: string
  gravatar_url: string
  has_password: boolean
  id: number
  industry: string
  is_saml_user: boolean
  job_title?: string
  last_name: string
  organization_ids: number[]
  status: number
  time_zone: string
}

type AdditionalUserData = {
  last_used_organization_id: number
  last_used_project_id: number
  max_number_of_organizations: number
  user_id: number
}

export type Account = {
  user: User
  additional_user_data: [AdditionalUserData]
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export type TestRun = {
  created: string
  delete_status: number
  distribution: [[string, number]]
  duration: number
  ended: string
  id: number
  is_baseline: boolean
  load_time: number
  nodes: TestRunNode[]
  note: string
  processing_status: number
  project_id: number
  result_status: number
  run_process: string
  run_status: number
  started: string
  test_id: number
  vus: number
  user_id: number
}

export type TestRuns = {
  'k6-runs': TestRun[]
  meta: Meta
}

export type TimeSeries = {
  value: Array<{
    contains: string
    method: string
    metric_name: string
    metric_type: string
    test_run_id: number
    values: Array<{
      timestamp: string
      value: number
    }>
  }>
}

export type ProjectWithOrg = Project & {
  orgName: string
}

export enum TestSortOptions {
  LastTestRun = '-last_test_run',
  Created = '-created',
  Name = 'name',
}

export type OrganizationRole = {
  id: number
  organization_id: number
  role_id: number
  user_email: string
  user_id: number
}

export type RunInfo = {
  'k6-metrics': Array<{
    check_id?: number
    contains: string
    group_id: string
    id: string
    name: string
    project_id: number
    tags: SeriesTag[]
    test_run_id: number
    type: string
    url_id?: number
  }>

  'k6-thresholds': Array<{
    calc_state: CalcState
    calculated_value: number
    id: number
    metric_id: string
    name: string
    stat: string
    tainted: boolean
    tainted_at: Date
    testrun_id: number
    value: number
  }>
}

type CalcState = {
  max_created_at: Date
  max_time: Date
  min_time: Date
  tainted_value: number
}

export type TimeSeriesQueryType = 'test_runs' | 'http_urls' | 'checks' | 'thresholds'

export type FetchTimeSeriesArgs = {
  queryType: TimeSeriesQueryType
  testRunId: number
  metric?: string
  method?: string
  uid?: string
  tags?: SeriesTag[]
}
