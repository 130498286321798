import { Test, TestSortOptions } from 'types'
import React, { useEffect, useState, FormEvent, SyntheticEvent } from 'react'
import { styles } from '../styles'
import { TestCard } from 'components/TestCard/TestCard'
import { useHistory } from 'react-router-dom'
import { HorizontalGroup, Icon, Input, Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import debounce from 'lodash/debounce'
import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { useDatasource } from 'datasourceContext'
import { useTests } from 'data/useTests'
import { InfiniteScroll } from 'components/InfiniteScroll'

const selectOptions = [
  { label: 'Last test run', value: TestSortOptions.LastTestRun },
  { label: 'Created', value: TestSortOptions.Created },
  { label: 'Name', value: TestSortOptions.Name },
]

const SEARCH_WAIT_INTERVAL = 300

export const TestsPage = () => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortOption, setSortOption] = useState<TestSortOptions | undefined>(TestSortOptions.LastTestRun)
  const trackFeature = useTrackFeature()
  const { project, projectId } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()
  const { ds } = useDatasource()
  const { tests, isLoading, loadNext } = useTests(projectId, searchValue, sortOption)

  const history = useHistory()

  useEffect(() => {
    if (!project) {
      return
    }
    setBreadcrumbs([{ path: `/projects/${project.id}`, name: project.name }])
  }, [project, setBreadcrumbs])

  const onRunTest = async (e: SyntheticEvent, test: Test) => {
    // TODO: check if we can remove this when RunsPage is hooked
    e.stopPropagation() // prevent redirect before test is started
    await ds.runTest(test.id)
    trackFeature('start_test')
    onOpenTest(test)
  }

  const onOpenTest = (test: Test) => {
    history.push(`/tests/${test.id}`)
  }

  const onSearchValueChanged = (search: string) => {
    setSearchValue(encodeURI(search))
  }

  const onSortOptionChanged = (selectedOption: SelectableValue<TestSortOptions>) => {
    setSortOption(selectedOption.value)
  }

  const debouncedOnInputChange = debounce(onSearchValueChanged, SEARCH_WAIT_INTERVAL)

  return (
    <>
      <HorizontalGroup justify={'space-between'}>
        <HorizontalGroup>
          <Input
            prefix={<Icon name="search" />}
            placeholder={'Search by test name'}
            onInput={(e: FormEvent<HTMLInputElement>) => {
              debouncedOnInputChange(e.currentTarget.value)
            }}
          />
          <Select options={selectOptions} value={sortOption} onChange={onSortOptionChanged} />
        </HorizontalGroup>
        <GoToK6Button type={ButtonTypes.Projects} id={projectId}></GoToK6Button>
      </HorizontalGroup>
      <br />
      <InfiniteScroll isLoading={isLoading} loadNext={loadNext}>
        <div className={styles.wrapper}>
          {tests.map((test) => (
            <TestCard test={test} key={test.id} run={onRunTest} open={onOpenTest} />
          ))}
        </div>
      </InfiniteScroll>
    </>
  )
}
