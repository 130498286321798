import React from 'react'
import styled from 'styled-components'

// @ts-ignore
const flagDir = require.context('circle-flags/flags')

const StyledFlag = styled.img`
  height: 100%;
`

export const Flag = ({ name }: { name: string }) => <StyledFlag src={flagDir(`./${normalizeCountryCode(name)}.svg`)} />

const normalizeCountryCode = (code: string) => {
  const mapping: Record<string, string | undefined> = {
    // We use SA for South Africa which is ZA in circle flags repo
    sa: 'za',
  }

  return mapping[code] || code
}
