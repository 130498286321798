import React from 'react'
import styled from 'styled-components'

import { TestRun, BadgeColors, BadgeColorCodes } from 'types'
import { getTestRunColorString } from 'pages/utils'

const Indicator = styled.span<{ $color: string }>`
  width: 16px;
  height: 16px;
  background: ${({ $color }) => $color};
  border-radius: 4px;
`

export const StatusIndicator = ({ testRun }: { testRun?: TestRun }) => {
  const statusColor = BadgeColorCodes[testRun ? getTestRunColorString(testRun) : BadgeColors.GRAY]

  return <Indicator $color={statusColor} />
}
