import React from 'react'
import { Card, Spinner } from '@grafana/ui'
import { get } from 'lodash-es'

import { separatorFormatter, responseTimeFormatter } from 'utils/formatters'
import { styles } from '../styles'
import { useSummary } from 'data/useSummary'
import { Summary, TestRun } from 'types'

const metrics = [
  {
    label: 'REQUESTS MADE',
    unit: 'reqs',
    key: 'http_metric_summary.requests_count',
    formatter: (val: number) => separatorFormatter(val),
  },
  {
    label: 'HTTP FAILURES',
    unit: 'reqs',
    key: 'http_metric_summary.failures_count',
    formatter: (val: number) => separatorFormatter(val),
  },
  {
    label: 'PEAK RPS',
    unit: 'reqs',
    key: 'http_metric_summary.rps_max',
    formatter: (val: number) => separatorFormatter(val),
  },
  {
    label: 'P95 RESPONSE TIME',
    unit: 'ms',
    key: 'http_metric_summary.duration.p95',
    formatter: (val: number) => responseTimeFormatter(val),
  },
]

const getValue = (summary: Summary | undefined, key: string, unit: string, formatter: (val: number) => string) => {
  if (!summary) {
    return <Spinner />
  }

  const value = get(summary, key)
  return `${formatter(value)} ${unit}`
}

export const RunSummary = ({ testRun }: { testRun: TestRun }) => {
  const { data: summary } = useSummary(testRun)

  return (
    <div className={styles.grid}>
      {metrics.map((metric) => (
        <Card key={metric.label}>
          <Card.Heading>{metric.label}</Card.Heading>
          <Card.Meta>
            <div>
              <span>{getValue(summary, metric.key, metric.unit, metric.formatter)}</span>
            </div>
          </Card.Meta>
        </Card>
      ))}
    </div>
  )
}
