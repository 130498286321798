import React, { createContext, useContext, ReactNode, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { K6DataSource } from 'datasource/datasource'
import { History } from 'history'

import { Environments, PROJ_ID_KEY } from 'constants/index'
import { Project, Account } from 'types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useDatasource } from 'datasourceContext'
import { useAccount } from 'data/useAccount'
import { useFetch } from 'data/useFetch'
import { DatasourceErrorMessage } from 'components/DatasourceErrorMessage'
import { CenteredSpinner } from 'components/CenteredSpinner'

type AppContextType = {
  orgId?: number
  isProd: boolean
  env: string
  project?: Project
  projectId: number
  account?: Account
}

type ProviderProps = {
  children: ReactNode
}

const AppContext = createContext<undefined | AppContextType>(undefined)

const getProject = async (ds: K6DataSource, projectId?: number) => {
  if (!projectId) {
    throw new Error('id is required')
  }

  const project = await ds.fetchProject(projectId)
  return project
}

const getDefaultProjectId = (account: Account) => {
  return account.additional_user_data[0].last_used_project_id
}

const fallbackToDefaultProject =
  (defaultProjectId: number, history: History, projectId?: number) => (exception: unknown) => {
    if (defaultProjectId === projectId) {
      throw exception
    }

    history.push(`/projects/${defaultProjectId}`)
  }

const fetchProject =
  (ds: K6DataSource, history: History) => async (id: number | undefined, defaultProjectId: number) => {
    return getProject(ds, id).catch(fallbackToDefaultProject(defaultProjectId, history, id))
  }

export const AppContextProvider = ({ children }: ProviderProps) => {
  const { ds } = useDatasource()
  const history = useHistory()
  const { pathname } = useLocation()

  const [savedProjectId, setSavedProjectId] = useLocalStorage<number | undefined>(PROJ_ID_KEY, undefined)

  const pathMatch = matchPath<{ projectId: string }>(pathname, { path: '/projects/:projectId' })
  const projectFromParams = pathMatch?.params?.projectId
  const projectId = (projectFromParams && +projectFromParams) || savedProjectId

  const { data: account, error: accountError } = useAccount()
  const { data: project } = useFetch(() => [projectId, getDefaultProjectId(account!)], fetchProject(ds, history))

  const env = ds.k6ApiEnv ?? Environments.Production
  const isProd = env === Environments.Production

  useEffect(() => {
    setSavedProjectId(projectId)
  }, [projectId, setSavedProjectId])

  if (accountError) {
    return <DatasourceErrorMessage />
  }

  if (!account || !project) {
    return <CenteredSpinner $height="150px" />
  }

  return (
    <AppContext.Provider
      value={{
        isProd,
        env,
        project,
        account,
        projectId: project.id,
        orgId: project.organization_id,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return context
}
