import { FieldConfigSource, ThresholdsMode } from '@grafana/data'

export const overviewPanelConfig: FieldConfigSource = {
  defaults: {
    min: 0,
    custom: {
      spanNulls: true,
      showPoints: 'never',
      lineWidth: 2,
    },
  },
  overrides: [
    {
      matcher: {
        id: 'byRegexp',
        // Need to match both "VUs" (displayed in run overview) and "value(vus)" (displayed in dashboards)
        options: /(VUs|value\(vus\))/.toString(),
      },
      properties: [
        {
          id: 'custom.fillOpacity',
          value: 10,
        },
        {
          id: 'color',
          value: {
            mode: 'fixed',
            fixedColor: '#86868659',
          },
        },
      ],
    },
  ],
}

export const getThresholdPanelConfig = (limit: number): FieldConfigSource => ({
  defaults: {
    min: 0,
    custom: {
      spanNulls: true,
      showPoints: 'never',
      lineWidth: 2,
      thresholdsStyle: {
        mode: 'line',
      },
    },
    thresholds: {
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          value: 0,
          color: 'green',
        },
        {
          value: limit,
          color: 'red',
        },
      ],
    },
  },
  overrides: [],
})

export const checksPanelConfig: FieldConfigSource = {
  defaults: {
    custom: {
      drawStyle: 'bars',
      fillOpacity: 100,
      lineWidth: 4,
      spanNulls: true,
    },
  },
  overrides: [],
}
