import { ArrayDataFrame, dateTime, LoadingState, PanelData, TimeRange } from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { LegendDisplayMode, PanelChrome } from '@grafana/ui'
import { K6DataSource } from 'datasource/datasource'
import React, { useState, useEffect } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { QueryType, TestRun } from 'types'
import { ClipBoard } from './Clipboard'
import { checksPanelConfig } from 'utils/panelConfig'

interface Props {
  data: any
  ds: K6DataSource
  run: TestRun
}

export const ChecksChart = (props: Props) => {
  const { run, ds, data } = props
  const dataId = data.id
  const isRefreshing = false
  const onCancelQuery = () => {}
  const noop = () => {}

  const [chartData, setChartData] = useState<any>({ series: [] })

  useEffect(() => {
    async function load(ds: K6DataSource) {
      const passesCall = ds.fetchTimeSeries({
        queryType: 'checks',
        testRunId: run.id,
        method: 'passes',
        uid: dataId,
      })

      const failsCall = ds.fetchTimeSeries({
        queryType: 'checks',
        testRunId: run.id,
        method: 'failures',
        uid: dataId,
      })

      const response = await Promise.all([passesCall, failsCall])

      const passes = response[0]
      const passesInfo = passes.value[0]
      let rangeValues: any[] = []
      const values: any[] = passesInfo?.values || []
      if (values.length > 0) {
        rangeValues = values
      }
      const label = passesInfo.method
      const passSeries = values.map((v: any) => ({
        timestamp: new Date(v.timestamp),
        [label]: parseInt(v.value, 10),
      }))

      const fails = response[1]
      const failsInfo = fails.value[0]
      const lbl = failsInfo.method
      const failValues: any[] = failsInfo?.values || []
      const failSeries = failValues.map((v: any) => ({
        timestamp: new Date(v.timestamp),
        [lbl]: parseInt(v.value, 10),
      }))

      const from = rangeValues[0]?.timestamp
      const to = rangeValues[rangeValues.length - 1]?.timestamp
      const r: TimeRange = {
        from: dateTime(from),
        to: dateTime(to),
        raw: { from, to },
      }

      const chartData = {
        passSeries,
        failSeries,
        range: r,
        passInfo: passesInfo,
        failInfo: failsInfo,
      }
      setChartData(chartData)
    }
    load(ds)
  }, [ds, run, dataId])

  const panelOptions = {
    legend: {
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
      calcs: [],
    },
    graph: {},
    tooltipOptions: {
      mode: 'single',
    },
  }

  const passFrame = new ArrayDataFrame(chartData.passSeries || [])
  const failFrame = new ArrayDataFrame(chartData.failSeries || [])

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: [passFrame, failFrame],
    timeRange: chartData.range,
  }

  return (
    <div>
      <ClipBoard
        name={ds.name}
        queryType={QueryType.CHECKS}
        testRun={run}
        itemId={dataId}
        fieldConfig={checksPanelConfig}
      />
      <AutoSizer disableHeight>
        {(size) => {
          return (
            <PanelChrome
              width={size.width}
              height={300}
              leftItems={[
                <PanelChrome.LoadingIndicator
                  loading={isRefreshing}
                  onCancel={onCancelQuery}
                  key="loading-indicator"
                />,
              ]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title=""
                  pluginId="timeseries"
                  onOptionsChange={noop}
                  width={innerWidth}
                  height={innerHeight}
                  data={panelData}
                  options={panelOptions}
                  fieldConfig={checksPanelConfig}
                />
              )}
            </PanelChrome>
          )
        }}
      </AutoSizer>
    </div>
  )
}
