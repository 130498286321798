import React, { useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelRenderer } from '@grafana/runtime'
import { PanelChrome, PanelContextProvider, LegendDisplayMode, PanelContext } from '@grafana/ui'
import styled from 'styled-components'

import {
  ArrayDataFrame,
  LoadingState,
  PanelData,
  TimeRange,
  dateTime,
  FieldConfig,
  EventBusSrv,
  EventBusExtended,
} from '@grafana/data'

import { QueryType, TestRun, TimeSeriesQueryType } from 'types'
import { useTimeSeries } from 'data/useTimeSeries'
import { ClipBoard } from 'components/Clipboard'
import { useDatasource } from 'datasourceContext'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { testIsActive } from 'pages/utils'
import { overviewPanelConfig } from 'utils/panelConfig'

type MetricPayload = {
  method: string
  metric: string
  unit: string
  label: string
  type: TimeSeriesQueryType
  id: number
}

const getTypes = (runId: number): MetricPayload[] => [
  { method: 'value', metric: 'vus', unit: 'VUs', label: 'VUs', type: 'test_runs', id: runId },
  { method: 'rps', metric: 'http_reqs', unit: 'reqs/s', label: 'req rate', type: 'test_runs', id: runId },
  { method: '0.95', metric: 'http_req_duration', unit: 'ms', label: 'resp time', type: 'test_runs', id: runId },
  {
    method: 'nz_rps',
    metric: 'http_req_failed',
    unit: 'reqs/s',
    label: 'failed rate',
    type: 'test_runs',
    id: runId,
  },
]

const panelOptions = {
  legend: {
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  graph: {},
  tooltipOptions: {
    mode: 'multi',
  },
  tooltip: {
    mode: 'multi',
  },
}

const appEvents: EventBusExtended = new EventBusSrv()
const context: PanelContext = {
  eventBus: appEvents,
  canAddAnnotations: () => false,
}

const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const OverviewChart = ({ testRun }: { testRun: TestRun }) => {
  const { ds } = useDatasource()

  return (
    <div>
      <ClipBoard name={ds.name} queryType={QueryType.METRIC} testRun={testRun} fieldConfig={overviewPanelConfig} />
      <AutoSizer disableHeight>
        {(size) => (
          <PanelChrome width={size.width} height={300} leftItems={[<div key="spacer" style={{ height: 32 }} />]}>
            {(innerWidth, innerHeight) => <Panel testRun={testRun} width={innerWidth} height={innerHeight} />}
          </PanelChrome>
        )}
      </AutoSizer>
    </div>
  )
}

const Panel = ({ testRun, width, height }: { testRun: TestRun; width: number; height: number }) => {
  const types = useMemo(() => getTypes(testRun.id), [testRun.id])
  const { data: series } = useTimeSeries(testRun, types)
  const getPanelData = () => {
    if (!series) {
      return undefined
    }

    const formattedSeries = (series || []).map((r, i) => {
      const values = r.value[0]?.values || []

      const label = types[i].label
      return values.map((v) => ({
        timestamp: new Date(v.timestamp),
        [label]: v.value,
      }))
    })

    if (formattedSeries.every((series) => series.length === 0)) {
      return undefined
    }

    const range: TimeRange = {
      from: dateTime(testRun.started),
      to: dateTime(testRun.ended),
      raw: { from: testRun.started, to: testRun.ended },
    }

    const frames = formattedSeries.map((item, i) => {
      const frame = new ArrayDataFrame(item)
      if (frame.fields && frame.fields.length > 0) {
        const cfg: FieldConfig = { unit: types[i].unit }
        frame.fields[1].config = cfg
      }
      return frame
    })

    const panelData: PanelData = {
      state: LoadingState.Error,
      series: frames,
      timeRange: range,
    }

    return panelData
  }

  const panelData = getPanelData()
  const isActive = testIsActive(testRun)
  const isLoading = !series || (!panelData && isActive)
  const noData = !panelData && !isActive

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (noData) {
    return <CenteredMessage>No data recorded</CenteredMessage>
  }

  return (
    <PanelContextProvider value={context}>
      <PanelRenderer
        title=""
        pluginId="timeseries"
        onOptionsChange={() => {}}
        width={width}
        height={height}
        data={panelData!}
        options={panelOptions}
        fieldConfig={overviewPanelConfig}
      />
    </PanelContextProvider>
  )
}
