import React from 'react'
import { DataFrame, dateTime, KeyValue, LoadingState, PanelData, TimeRange, FieldConfigSource } from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { PanelChrome } from '@grafana/ui'
import AutoSizer from 'react-virtualized-auto-sizer'

import { TestRun } from 'types'
import { MAX_VALUE_EMPTY_BARS } from 'constants/index'
import { formatRunsForDataFrame, getTestRunColorString, loadFormattedRunsToDataframes, padEmptyBars } from 'pages/utils'

export const TrendingChart = ({ sortedRuns }: { sortedRuns: TestRun[] }) => {
  const { panelData, panelOptions, overrides, fieldConfig } = getPanelData(sortedRuns)

  return (
    <AutoSizer disableHeight>
      {(size) => (
        <PanelChrome width={size.width} height={70}>
          {(innerWidth, innerHeight) => (
            <PanelRenderer
              title="Trending p95 response time"
              pluginId="bargauge"
              width={innerWidth}
              height={innerHeight}
              data={panelData}
              options={panelOptions}
              fieldConfig={{ ...fieldConfig, overrides } as FieldConfigSource}
            />
          )}
        </PanelChrome>
      )}
    </AutoSizer>
  )
}

const getPanelData = (sortedRuns: TestRun[]) => {
  const MAX_BARS = 20
  let maxValue = 0

  const runsWithData = sortedRuns
    .map((run) => {
      maxValue = Math.max(maxValue, run.load_time)

      if (run.load_time === null) {
        return { ...run, load_time: 0 }
      }

      return run
    })
    .reverse()

  if (maxValue === 0) {
    maxValue = MAX_VALUE_EMPTY_BARS
  }

  let runsToPlot = runsWithData
  if (runsWithData.length < MAX_BARS) {
    runsToPlot = padEmptyBars(runsWithData, MAX_BARS - runsWithData.length)
  }

  const rto = runsToPlot[0]?.created
  const rfrom = runsToPlot[runsToPlot.length - 1]?.created
  const range: TimeRange = {
    from: dateTime(rfrom),
    to: dateTime(rto),
    raw: { from: rfrom, to: rto },
  }

  const formattedRuns = formatRunsForDataFrame(runsToPlot)
  const frames: DataFrame[] = loadFormattedRunsToDataframes(formattedRuns)

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: frames,
    timeRange: range,
  }

  const panelOptions = getPanelOptions()
  const overrides = getOverrides(formattedRuns)
  const fieldConfig = getFieldConfig(maxValue)

  return { panelData, panelOptions, overrides, fieldConfig }
}

const getPanelOptions = () => {
  return {
    displayMode: 'gradient',
    orientation: 'auto',
    reduceOptions: {
      calcs: ['lastNotNull'],
      fields: '',
      limit: 1000,
      values: true,
    },
    showUnfilled: true,
    text: {
      titleSize: 0,
      valueSize: 0,
    },
  }
}

const getOverrides = (formattedRuns: KeyValue) => {
  return Object.entries(formattedRuns).map((run) => {
    const statusColor = getTestRunColorString(run[1].status)
    return {
      matcher: {
        id: 'byFrameRefID',
        options: run[0],
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: statusColor,
            mode: 'fixed',
          },
        },
      ],
    }
  })
}

const getFieldConfig = (maxValue: number) => {
  return {
    defaults: {
      color: {
        mode: 'thresholds',
      },
      mappings: [],
      min: 0,
      max: maxValue,
      thresholds: {
        mode: 'absolute',
        steps: [
          {
            color: 'green',
            value: null,
          },
          {
            color: 'red',
            value: 80,
          },
        ],
      },
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'failed',
        },
        properties: [
          {
            id: 'color',
            value: {
              mode: 'fixed',
              fixedColor: 'red',
            },
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'passed',
        },
        properties: [
          {
            id: 'color',
            value: {
              mode: 'palette-classic',
            },
          },
          {
            id: 'color',
            value: {
              mode: 'fixed',
              fixedColor: 'green',
            },
          },
        ],
      },
    ],
  }
}
