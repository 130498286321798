import { css } from 'emotion'
import { createTheme } from 'react-data-table-component'

export const styles = {
  capitalize: css`
    text-transform: capitalize;
  `,
  copyButton: css`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  `,
  alert: css`
    position: absolute;
    z-index: 1000;
    top: -75px;
    width: 50%;
    right: 5px;
    width: 300px;
  `,
  link: css`
    color: rgb(125, 100, 255);
  `,
  logo: css`
    height: 30px;
    margin-right: 10px;
    margin-left: -10px;
    vertical-align: middle;
  `,
  chartWrapper: css`
    div {
      div {
        border: none;
      }
    }
  `,
  crumbs: css`
    padding: 10px;
    // padding-left: 40px !important;
    display: flex;
    /* align-content: space-between; */
    justify-content: space-between;
    div {
      // line-height: 30px;
    }
  `,
  wrapper: css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 10px;
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    column-gap: 10px;
  `,
  ml1: css`
    margin-left: 4px;
  `,
  mb2: css`
    margin-bottom: 8px;
  `,
  settings: css`
    width: 400px;
  `,
  settingsButton: css`
    margin-top: 5px;
  `,
  marginWidthAuto: css`
    margin: auto;
    width: auto;
  `,
  displayFlex: css`
    display: flex;
  `,
  maginRight5px: css`
    margin-right: 5px;
  `,
  marginLeftRight5px: css`
    margin: 0 5px 0 5px;
  `,
  marginRight30px: css`
    margin-right: 30px;
  `,
}

export const loadingCSS = {
  height: '20px',
  margin: '30px',
}

export const createTableTheme = () =>
  createTheme('grafana-dark', {
    text: {
      primary: 'rgb(204, 204, 220)',
      secondary: '#2aa198',
    },
    background: {
      default: '#181b1f;',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: 'rgba(204, 204, 220, 0.07)',
    },
    highlightOnHover: {
      default: '#111217',
      text: '#FFFFFF',
    },
    // button: {
    //   default: '#2aa198',
    //   hover: 'rgba(0,0,0,.08)',
    //   focus: 'rgba(255,255,255,.12)',
    //   disabled: 'rgba(255, 255, 255, .34)',
    // },
    sortFocus: {
      default: '#2aa198',
    },
  })
