import { useDatasource } from 'datasourceContext'
import { TestRun, TimeSeriesQueryType } from 'types'
import { useFetch } from './useFetch'

type MetricPayload = {
  method: string
  metric: string
  unit: string
  label: string
  type: TimeSeriesQueryType
  id: number
}

export const useTimeSeries = (testRun: TestRun, types: MetricPayload[]) => {
  const { ds } = useDatasource()

  const fetchSeries = (runId: number) => {
    return Promise.all(
      types.map((t) =>
        ds.fetchTimeSeries({
          queryType: t.type,
          testRunId: runId,
          metric: t.metric,
          method: t.method,
        })
      )
    )
  }

  return useFetch([testRun, 'series', types], (testRun) => fetchSeries(testRun.id), {
    useStickyData: true,
  })
}
