import React from 'react'
import { last } from 'lodash-es'
import styled from 'styled-components'

import { ToolbarButton } from '@grafana/ui'
import { TestRun, Test } from 'types'
import { LastRunMeta } from '../LastRunMeta'
import { TrendingChart } from './TrendingChart'
import { StatusIndicator } from './StatusIndicator'

interface Props {
  test: Test
  run: Function
  open: Function
}

const Card = styled.div`
  background: ${(props) => props.theme.colors.background.secondary};
  padding: 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  section {
    margin-bottom: 10px;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    margin-left: 10px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 11px;
    flex: 1;
  }
`

export const TestCard = ({ test, run, open }: Props) => {
  const sortedRuns = getSortedTestRuns(test.test_runs)

  return (
    <Card onClick={() => open(test)}>
      <Header>
        <StatusIndicator testRun={last(sortedRuns)} />
        <h5>{test.name}</h5>
        <ToolbarButton icon="play" tooltip="Start test" onClick={(e) => run(e, test)} />
      </Header>

      <section>
        <LastRunMeta testRuns={sortedRuns} />
      </section>

      <TrendingChart sortedRuns={sortedRuns} />
    </Card>
  )
}

export const getSortedTestRuns = (testRuns: TestRun[]) => {
  return [...testRuns].sort((a, b) => {
    return new Date(a.created).getTime() - new Date(b.created).getTime()
  })
}
