import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react'
import { K6DataSource } from 'datasource/datasource'
import { getDataSourceSrv } from '@grafana/runtime'

import { DS_ID_KEY, DATASOURCE_TYPE } from 'constants/index'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { AddDatasource } from 'components/AddDatasource'

type DatasourceContextType = {
  ds: K6DataSource
  setActiveDsId: (id: number, callback?: () => void) => void
  getDsList: () => Promise<K6DataSource[]>
}

type ProviderProps = {
  children: ReactNode
}

const DatasourceContext = createContext<undefined | DatasourceContextType>(undefined)

const srv = getDataSourceSrv()

export const DatasourceContextProvider = ({ children }: ProviderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [activeDsId, setActiveDsId] = useLocalStorage<number | undefined>(DS_ID_KEY, undefined)
  const [ds, setDs] = useState<K6DataSource>()

  const dsList = React.useMemo(() => {
    return srv.getList().filter((d) => d.type === DATASOURCE_TYPE)
  }, [])

  const getDs = useCallback(
    async (dsId: number) => {
      const dsMatch = dsList.find((d) => d.id === dsId)
      if (!dsMatch) {
        setActiveDsId(undefined)
        return
      }

      const ds = (await srv.get(dsMatch.uid)) as K6DataSource
      setDs(ds)
    },
    [setActiveDsId, dsList]
  )

  useEffect(() => {
    if (!activeDsId) {
      // Use first datasource as default
      dsList.length && setActiveDsId(dsList[0].id)
    }
  }, [activeDsId, setActiveDsId, dsList])

  useEffect(() => {
    if (activeDsId) {
      setIsLoading(true)
      getDs(activeDsId).then(() => setIsLoading(false))
    }
  }, [activeDsId, getDs])

  const handleSetActiveDsId = (dsId: number, callback?: () => void) => {
    if (dsId !== activeDsId) {
      setIsLoading(true)
      setActiveDsId(dsId)
    }
    callback && callback()
  }

  const getK6DsList = () => {
    return Promise.all(dsList.map((ds) => srv.get(ds.uid) as Promise<K6DataSource>))
  }

  if (isLoading) {
    // TODO: add spinner
    return null
  }

  if (ds === undefined || dsList.length === 0) {
    return <AddDatasource />
  }

  return (
    <DatasourceContext.Provider value={{ ds, setActiveDsId: handleSetActiveDsId, getDsList: getK6DsList }}>
      {children}
    </DatasourceContext.Provider>
  )
}

export const useDatasource = () => {
  const context = useContext(DatasourceContext)

  if (context === undefined) {
    throw new Error('useDatasource must be used within a DatasourceContextProvider')
  }

  return context
}
