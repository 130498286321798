import React from 'react'
import { PanelRenderer } from '@grafana/runtime'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelChrome } from '@grafana/ui'
import { DataFrame, dateTime, LoadingState, PanelData, TimeRange, ThresholdsMode } from '@grafana/data'

import { TestRun } from 'types'
import { loadFormattedRunsToDataframes, formatRunsForDataFrame, getTestRunColorString, padEmptyBars } from '../utils'
import { MAX_VALUE_EMPTY_BARS } from 'constants/index'
import { styles } from 'pages/styles'

const MAX_BARS = 20

const panelOptions = {
  displayMode: 'gradient',
  orientation: 'auto',
  reduceOptions: {
    calcs: ['lastNotNull'],
    fields: '',
    limit: 1000,
    values: true,
  },
  showUnfilled: true,
  text: {
    titleSize: 0,
    valueSize: 0,
  },
}

const getFieldConfig = (maxValue: number) => ({
  defaults: {
    color: {
      mode: 'thresholds',
    },
    mappings: [],
    min: 0,
    max: maxValue,
    thresholds: {
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          color: 'green',
          value: 0,
        },
      ],
    },
  },
})

export const TrendingChart = ({ runs }: { runs: TestRun[] }) => {
  let runsToPlot = runs
  const maxLoadTime = Math.max(...runs.map((run) => run.load_time)) || MAX_VALUE_EMPTY_BARS

  if (runs.length < MAX_BARS) {
    runsToPlot = padEmptyBars(runs, MAX_BARS - runs.length)
  }

  const formattedRuns = formatRunsForDataFrame(runsToPlot)
  const frames: DataFrame[] = loadFormattedRunsToDataframes(formattedRuns)

  const overrides = Object.entries(formattedRuns).map((run) => {
    const statusColor = getTestRunColorString(run[1].status)
    const override = {
      matcher: {
        id: 'byFrameRefID',
        options: run[0],
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: statusColor,
            mode: 'fixed',
          },
        },
      ],
    }
    return override
  })

  const rto = runs[0]?.created
  const rfrom = runs[runs.length - 1]?.created
  const range: TimeRange = {
    from: dateTime(rfrom),
    to: dateTime(rto),
    raw: { from: rfrom, to: rto },
  }

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: [...frames],
    timeRange: range,
  }

  return (
    <div className={styles.chartWrapper}>
      <AutoSizer disableHeight>
        {(size) => {
          return (
            <PanelChrome
              title="Trending response time (95th percentile)"
              width={size.width}
              height={300}
              leftItems={[<PanelChrome.LoadingIndicator loading={false} onCancel={() => {}} key="loading-indicator" />]}
            >
              {(innerWidth, innerHeight) => (
                <PanelRenderer
                  title="Trending response time (95th percentile)"
                  pluginId="bargauge"
                  onOptionsChange={() => {}}
                  width={innerWidth}
                  height={innerHeight}
                  data={panelData}
                  options={panelOptions}
                  fieldConfig={{ ...getFieldConfig(maxLoadTime), overrides }}
                />
              )}
            </PanelChrome>
          )
        }}
      </AutoSizer>
    </div>
  )
}
